.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}

.progress-bar {
  width: 100%;
  height: 5px;
  /* Adjust the height as needed */

  display: flex;
  border-radius: 10px;
  /* Add rounded corners */
  overflow: hidden;
  gap: 10px;
}

.progress-bar-step {
  background-color: #C1CEFF;
  height: 100%;
  transition: background-color 0.3s ease, width 0.3s ease;
  border-radius: 10px;
  /* Add rounded corners to each step */
}

.progress-bar-step.filled {
  background-color: #081439;
}

/* Add space between each step */
.gap-1 .progress-bar-step {
  margin-right: 1%;
}

.step-count {
  font-size: 16px;
  color: #081439;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #3498db;
}