/* LogIn.css */

.background-image {
    position: relative;
    background-color: #081439;
    overflow: hidden;
    border-radius: 24px;

}

.background-image::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    background-image: url('../../assets/img/sl_031520_28970_27\ 1.png');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
}

.login-container {
    display: flex;
    flex-direction: row;
    padding: 24px;
    width: 100%;
    gap: 24px;
    /* background-color: #f6fafb80; */
    height: 100vh;
    box-sizing: border-box;
    min-width: 492px;
    align-items: center;

}

.login-side {
    flex-grow: 1;
    min-height: 100%;
}

.login-main {
    width: clamp(min-content, 25%, max-content);
    gap: 24px;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-main-content {
    background-color: #fffffff0;
    padding: 48px;
    border-radius: 24px;
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    min-width: 492px;
    max-height: fit-content;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.116);
}

.img-logo {
    width: 265px;
    height: 76px;
}

.img-confirm {
    width: 324px;
    height: 224px;
}


.img-left {
    width: 624px;
    height: 324px;
}

.icon-focused {
    color: #507CED;
    /* or any color you prefer */
}

.img-size {
    width: 24px;
    cursor: pointer;
}

.verify-email-container {
    display: flex;
    flex-direction: row;
    padding: 24px;
    width: 100%;
    gap: 24px;
    /* background-color: #F6FAFB; */
    height:auto;
    box-sizing: border-box;
    min-width: 492px;
    justify-content: center;
}

.verify-email-main {
    width: clamp(min-content, 25%, max-content);
    gap: 24px;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    justify-content: center;
    align-items: center;
    height: max-content;
}

.verify-email-content {
    background-color: #FFFFFF;
    padding: 48px;
    border-radius: 12px;
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    min-width: 492px;
    max-height: fit-content;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
}

.verify-email-content2 {
    background-color: #FFFFFF;
    padding: 10px 24px 24px 24px;
    border-radius: 12px;
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    min-width: 492px;
    max-height: fit-content;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
}

.title {
    font-size: 1.3rem;
    color: rgba(45, 53, 70, 1);
    text-align: center;
    font-weight: 600;
}

.subtitile {
    font-size: 0.8rem;
    color: rgba(45, 53, 70, 1);
    text-align: center;
}


@media only screen and (max-width:768px) {
    .login-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        height: fit-content;
    }

    .login-main {
        width: 100%;
        height: min-content;

    }

    .login-main-content {
        height: min-content;
        padding: 72px;

    }

    .login-side {
        width: 100%;
        padding-top: 24px;
    }
}

@media only screen and (max-width: 1080px) {
    .login-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        height: fit-content;
        align-items: center;

    }

    .login-side {
        width: 100%;
        padding-top: 24px;
    }

    .login-main {
        width: 100%;
        height: min-content;

    }

    .login-main-content {
        height: min-content;
        padding: 72px;

    }
}

@media only screen and (min-width: 1440px) {
    .login-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: screen;

    }

    .login-main {
        width: 70%;
        justify-content: center;
        align-items: center;

    }

    .login-main-content {
        width: 70%;
    }

    .login-side {
        width: 100%;
    }
}
.container {
    height: 100vh;
    width: 100%;
    background-color: white;
    padding: 8px 4px 8px 4px;
  }
  
  .observation-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem;
  }
  
  .icon-container {
    position: relative;
    margin-top: 0.25rem;
    display: grid;
    grid-auto-flow: row;
    gap:4px;
   
  }
  
  .indicator {
    height: 4px;
    width: 4px;
    position: absolute;
    top: 0;
    left: 1rem;
    background-color: red;
    border-radius: 50%;
  }
  
  .icon-wrapper {
    margin-top: 0.5rem;
  }
  
  .info {
    display: flex;
    flex-direction: column; 
   
  }
  
  .title {
    font-size: 1rem;
    color:#2e343e;
  }
  
  .subtitle {
    font-size: 0.875rem;
    color: #7C8AA6;
  }
  
  .custom-name-column {
    background-color: blue;
    color: white;
    border-radius: 5px;
  }
  .container {
    position: relative;
   height: 20px;
  
   margin-top: 10px;
}

.content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: top 0.5s ease;
    
}

.showPrice {
    top: calc(100% + 10px); /* Adjust the offset as needed */
}

.showPercentage {
    top: calc(-100% - 10px); /* Adjust the offset as needed */
}
.rounded-xl-tr {
    border-radius: 3rem; /* Adjust the value as needed */
  }
  .different-rate {
    background-color: red;
    text-decoration: black; /* Change this color to your preferred color */
}




 
 
.tabcol:nth-child(1) {
    width: 25%;
}
.tabcol:nth-child(2) {
    width: 25%;
}
.tabcol:nth-child(3) {
    width: 25%;
}
.tabcol:nth-child(4) {
    width: 25%;
}
.tabcol:nth-child(5) {
    width: 25%;
}
.tabcol:nth-child(6) {
    width: 25%;
}
.scale-left {
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  .scale-left:hover {
    transform: scaleX(1.1);
  }
  .move-left {
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  .move-left:hover {
    transform: translateX(-8px); /* Move 20px to the right */
  }
  .scroll-container {
    /* Set dimensions and other styles as needed */
    overflow-y: scroll; /* Enable vertical scrolling */
  }
  
  /* Hide the scrollbar for WebKit (Chrome, Safari, etc.) */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  .hover-div {
    color:  #59647B;
   
  }
  
  .hover-div:hover {
    color: #081439;
  }
  .hoverEffect {
    filter: brightness(100%) saturate(100%) invert(1);
  }
  