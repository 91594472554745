/* NotificationComponent.css */
.notification-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px; /* Adjust the width as needed */
  }
  
  .notification {
    height: 50px; /* Adjust the height as needed */
    background-color: #3498db; /* Adjust the background color as needed */
    margin-bottom: -45px; /* Negative margin to overlap 90% */
    transition: margin-bottom 0.5s ease;
    cursor: pointer; /* Add cursor pointer for better UX */
  }
  
  .hide-behind {
    opacity: 0.1; /* Adjust the opacity as needed */
  }
  
  .show-all .notification {
    margin-bottom: 0; /* Show all notifications completely */
  }
  