:root {
    --main-light-bg-color: #ECE6F0;
    --main-dark-bg-color: rgb(61, 145, 255);
    --main-range-bg-color: #E8DEF8;
    --main-modal-bg-color: #ffffff;
    --main-date-width: 40px;
  }


.calendarButton{
    background-color: black;
    color: white;
    padding: 8px;
    border-radius: 8px;
    margin: 3px
}

.calendarBlock{
    position: absolute;
    height: fit-content;
    width: fit-content;
    background-color: #ECE6F0;
    z-index: 99;
    border: black;
    border-radius: 2px ;
    translate: -380px 10px;
    border-radius: 5px;
    background-color: #c8c8c8;
    font-size: 15px;
}

/* dateComponent css */
.defaultDate{
    width: var(--main-date-width);
    height: var(--main-date-width);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.disabledDate{
    color: gray;
    width: var(--main-date-width);
    height: var(--main-date-width);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.nullDate{
    visibility: hidden;
    width: var(--main-date-width);
    height: var(--main-date-width);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.rangeEndDate{
    color: var(--main-dark-bg-color);
    background: var(--main-light-bg-color);
    width: var(--main-date-width);
    height: var(--main-date-width);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0px  100px 100px 0px;
}
.rangeStartDate{
    color: var(--main-dark-bg-color);
    background: var(--main-light-bg-color);
    width: var(--main-date-width);
    height: var(--main-date-width);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px 0px 0px 100px;
}
.rangeMiddleDate{
    color: var(--main-dark-bg-color);
    background: var(--main-light-bg-color);
    width: var(--main-date-width);
    height: var(--main-date-width);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.selectedDate{
    color: white;
    background: var(--main-dark-bg-color);
    width: var(--main-date-width);
    height: var(--main-date-width);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--main-dark-bg-color);
}
.todayDate{
    color: var(--main-dark-bg-color);
    background: var(--main-light-bg-color);
    width: var(--main-date-width);
    height: var(--main-date-width);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--main-dark-bg-color);
}
.weekDaysDate{
    width: var(--main-date-width);
    height: var(--main-date-width);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
}
.todayDate:hover, .selectedDate:hover, .defaultDate:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

/* calendar css */
.calendarModal{
    position: absolute;
    width: fit-content;
    height: fit-content;
    min-width: 370px;
    margin: 5px;
}
.calendarModalHeader{
    background-color: var(--main-modal-bg-color);
    border-radius: 20px;
    border-left: solid 2px #55555525;
    border-right: solid 2px #00000025;
    border-bottom: solid 2px #00000025;
    border-top: solid 2px #55555525;
    border-radius: 5px;
}
.calendar-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .week-row {
    display: flex;
  }
  
  .day-cell {
    flex: 1;
    padding: 10px;
    text-align: center;
  }
  .yearMonthRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .yearRow{
    display: flex;
    flex-direction: row;
  }
  .monthRow{
    display: flex;
    flex-direction: row;
  }
  .monthSelection{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .modalButtonRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .cancelOkButtonRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .calendarModalButton{
    color: var(--main-dark-bg-color);
    font-weight: 600;
    margin-right: 30px;
  }
  .monthCalendarGrid{
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .monthCalendarGridButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: start;
    align-items: center;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
  }
  .disabledMonthCalendarGridButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: start;
    align-items: center;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    color: gray;
  }
  .monthCalendarGridButton:hover, .yearCalendarGridButton:hover{
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2%;
  }
  .selectedMonth{
    background-color: var(--main-dark-bg-color);
    color: white;
    border-radius: 2%;
  }
  .selectedYear{
    background-color: var(--main-dark-bg-color);
    color: white;
    border-radius: 10%;
  }
  .notSelectedMonth{
    visibility: hidden;
  }

  .divider{
    height: 1px;
    width: 100%;
    background-color: gray;
  }

  .yearCalendarGrid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content; /* Set the desired fixed height */
    overflow-y: scroll;
  }
  
  .year-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .year-list-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .year-list-item:hover {
    background-color: #f0f0f0;
  }

  .disabled{
    color: gray;
    display: none;
  }

  .yearCalendarGridButton{
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .disabledYearCalendarGridButton{
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
  }

  .yearCalendarGridButton:hover{
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10%;
  }