@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .display-color {
    @apply bg-neutral-dark-2;
  }
}
@layer base {
	img {
		@apply inline-block;
	}
}
body {
  /* background-color: #F6F6FB; */
  /* background: #C8BFCF; */
  /* background: radial-gradient(ellipse farthest-corner at bottom right, #c8bfcfac 0%, #fefdce9b 38%, #a0becfa2 85%); */
 /* background-image: url('https://static.vecteezy.com/system/resources/previews/008/253/321/non_2x/blurred-background-with-light-color-gradient-vector.jpg');  */
 /* background-image: url('https://static.vecteezy.com/system/resources/previews/008/253/321/non_2x/blurred-background-with-light-color-gradient-vector.jpg');  */
 /* background-image: linear-gradient(to right bottom, #eefaff, #e7f4f8, #e0eff1, #dae9e9, #d4e3e2, #ccdcdc, #c4d6d7, #bccfd2, #b3c5ce, #aebac8, #adaec0, #ada2b4); */
 /* background-image: url("../src/assets/rateXdarkBg.jpeg"); */
 /* background-image: url("../src/assets/rateXlightBg.png"); */
 /* background-image: url("https://s3-alpha-sig.figma.com/img/73ae/73dd/d96b236adac48f90570b6bb9e5688522?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KzbaTUNUR2HSbJFsCxHD-9b8O0KVf6hfflswNI2TLAgxW9itua2xGgDlSlc9EMnTLRw7Q7DrkpfsDWTR1Gd-EGLY4R7Y-znbvSgfSP30F7x2KZO4WVW2oJt0nEqKPVtdHVM3Qnhrn~Ts~gohSoBq~hIXf-jlsEcjXm3qtL83hKSbmGvSfxnWx3hPYePDNqkY4LLGx3yCiElf-jtcxZfvV4PSTWGaSWj4v5LeGxzjjwnMnCMp-2rCdShxFOoA5uAPAkx52hjQ8G1xxJhNf4sxW-mDC6wTs9gYAmE1vOSTim6gueR-~qGyCcoi8o~yHVX9WZ-C8J09Bev7x9oqDF0tig__"); */
 /* background-image: url('../src/assets/ratexbg.jpg'); */
  background-size: cover;
  
  /* background: linear-gradient(28deg,#b5dae4,#fff9dea8,#f8f9fa,#708692);
  background-size: 240% 240%;
  animation: gradient-animation 16s ease infinite; */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
*{
  font-family: "Roboto", sans-serif !important;
  scroll-behavior: smooth !important;
}

.sidebar_toggle {
  border: 7px solid #f5f5f9;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -25px;
  float: right;
  border-radius: 100%;
  background: #081439;
}


:-webkit-autofill {
  /* -webkit also works in firefox here */
  filter: none; /* needed for firefox! */
  box-shadow: 0 0 0 100px rgb(255, 255, 255) inset;
  background: #081439;
}

.toast-position {
  bottom: 4rem !important;
}

.apexcharts-xcrosshairs {
  stroke-linecap: round;
  stroke-width: 1px;
  stroke-dasharray: 0;
  stroke: rgb(0, 0, 0);
}

.scrollHide::-webkit-scrollbar {
  display: none;
}

/* styles.css */

.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

* {
  font-family: "Inter", sans-serif !important;
}


.custom-tooltip {
  background-color: #f2f2f2; /* Background color for the tooltip */
  color: #03020c; /* Text color for the tooltip */
  padding: 5px 25px; /* Padding around the content */
  border-radius: 5px; /* Border radius for rounded corners */
}

.custom-text-tooltip-first{
  font-size: 9px;
  font-weight: 500;
  color: #2D3546;
}

.custom-text-tooltip-second{
  font-size: 14px;
  font-weight: 500;
  color: #2D3546;
}

.custom-text-tooltip-third{
  font-size: 16px;
  font-weight: 700;
  color: #2D3546;
}

.react-data-table .rdt_TableRow:hover {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.react-data-table .rdt_TableRow:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 20%;
  width: 5px;
  background-color: #081439; 
}

.react-data-table .rdt_TableRow:hover .rdt_TableCell:first-child {
  transform: translateX(10px); 
  transition: transform 0.3s ease;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

li[class="slide selected"]{
  border: none !important;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  /* Apply animation to the image */
  animation: rotateAnimation 4s linear infinite; /* Change 4s to adjust speed */
}

.slide.selected{
    border: none !important;
}

.apexcharts-legend{
  display: none !important;
}

svg path[d="M7 10l5 5 5-5z"]{
  display: none;
}

select[aria-label="Rows per page:"]{
  background-image: none;
  appearance: auto;
}

.react-data-table .rdt_TableRow:hover{
  overflow: visible !important;
}

.typewriter-container {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  border-right: .15em solid orange; /* Cursor effect */
  white-space: nowrap;
  animation: typing 4s steps(40, end), blink-caret .75s step-end infinite;
  display: inline-block;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

.typewriter-text {
  display: inline-block;
  animation: typing-text 4s steps(40, end);
  white-space: pre-wrap; /* Allows text to wrap */
}

@keyframes typing-text {
  from { width: 0; }
  to { width: 100%; }
}



.typewriter {
  overflow: hidden; /* Ensures the text is hidden until it is revealed */
  border-right: .15em solid orange; /* Creates the cursor effect */
  white-space: nowrap; /* Prevents the text from wrapping */
  margin: 0 auto; /* Centers the text */
  letter-spacing: .15em; /* Adds space between characters */
  display: inline-block; /* Allows the width property to be animated */
  width: 100%; /* Initial width set to 0 for the animation */
  overflow: hidden; /* Ensures the text is hidden until it is revealed */
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

.no-caret{
  border-right: none; /* Removes the cursor effect */
}

.glassMorph{
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25px) saturate(200%);
  background-color: rgb(255 255 255 / 46%);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.rdt_Pagination{
  border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}



.water-layer {
  background: #1a386b;
  position: absolute;
}

.layer-1 {
  animation: wave1 4s infinite linear;
}

.layer-2 {
  animation: wave2 6s infinite linear;
  opacity: 0.7;
}

.layer-3 {
  animation: wave3 8s infinite linear;
  opacity: 0.5;
}

/* Wave animation for the first layer */
@keyframes wave1 {
  0% {
    transform: translateX(0) translateY(8%);
  }
  50% {
    transform: translateX(0px) translateY(-1%);
  }
  100% {
    transform: translateX(0) translateY(8%);
  }
}

/* Wave animation for the second layer */
/* @keyframes wave2 {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(0px) translateY(-15%);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
} */

/* Wave animation for the third layer */
@keyframes wave1 {
  0% {
    transform: translateX(0) translateY(-8%);
  }
  50% {
    transform: translateX(0px) translateY(5%);
  }
  100% {
    transform: translateX(0) translateY(-8%);
  }
}



