.calendar-container {
  /* width: 100%; */
  padding: 10px;
  border-radius: 10px;
  /* max-width: 300px; */
  margin: 0 auto;
  position: absolute;
  /* margin-right: 20px; */
  background: white;
  margin-top: 10px;
  z-index: 1 !important;
  top: 5;
  /* position: relative; */
  z-index: 999;
}
.calendar-div {
  /* width: 100%; */
  padding: 10px;
  border-radius: 10px;
  /* max-width: 300px; */
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 12px;
  /* margin-right: 20px; */
  background: white;

  z-index: 1 !important;


}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.arrow {
  cursor: pointer;
}

.month-year {
  font-size: 14px;
}

.calendar {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
}

.weekdays {
  display: flex;
  justify-content: space-around;
  /* background-color: #f0f0f0; */
  font-weight: bold;
  margin-top: 10px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  border: 1px solid transparent;
  padding: 10px;
  cursor: pointer;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  /* margin: 12px; */
}

.empty-day {
  visibility: hidden;
}

.past-day {
  color: #a8b1c4;
}

.past-day-disabled {
  color: #a8b1c4;
  cursor: not-allowed;
}

.current-day {
  color: #ff5733; /* Adjusted color for better visibility */
  background-color: #ffe6cc; /* Added background color for better highlighting */
}

.normal-day {
  color: #2d3546;
}
.monthyearview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  justify-items: center;
}
.month {
  width: 32%;
  padding: 20px;
  font-size: 12px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.year {
  width: 32%;
  padding: 20px;
  font-size: 12px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  border: 1px solid #081439; /* Your selected date color */
  border-radius: 50%; /* Text color for selected date */
}

