.liquidButton {
    position: relative;
    padding: 24px 36px;
    margin: 10px 0px;
    color: white;
    text-decoration: none !important;
    font-size: 1.4em;
    letter-spacing: 2px;
    font-family: "sans-serif";
    border-radius: 24px;
    /* background: linear-gradient(90deg, #29CC39, #29CC39); */
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 265px;
    box-sizing: border-box;
    margin-top: -1px;
}

.liquidButton__text {
    font-weight: 700;
    font-size: xx-large;
    text-align: center;
    justify-content: center;
}

.liquidButton__text2 {
    font-weight: 200;
    font-size: x-small;
    text-align: center;
    font-family: sans-serif;
}

.div1 {
    z-index: 10;
    top: 30%;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.liquidButton__liquid {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 500px;
    /* background: #29CC39; */
    box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

/* .liquidButton:hover .liquidButton__liquid {
    top: -120px;
} */

.liquidButton__liquid:before,
.liquidButton__liquid:after {
    content: "";
    position: absolute;
    top: 1%;
    left: 50%;
    width:180%;
    height: 120%;
    transform: translate(-50%, -75%);
}

/* .liquidButton__liquid:before {
    border-radius: 40%;
    background-color: #58c864;
    animation: animate 8s linear infinite;
}

.liquidButton__liquid:after {
    border-radius: 45%;
    background-color: #3f9b4b;
    animation: animate 10s linear infinite;
} */

.redTheme::before,
.redTheme::after {
    border-radius: 45%;
    animation: animate 8s linear infinite;
}

.redTheme::before {
    background-color: #FF4136;
}

.redTheme::after {
    background-color: #f1903b;
}


.yellowTheme::before {
    background-color: #FFD700;
    border-radius: 45%;
    animation: animate 6s linear infinite;
}

.yellowTheme::after {
    background-color: #FAD5AB;
    border-radius: 48%;
    animation: animate 4s linear infinite;
}

.greenTheme::before,
.greenTheme::after {
    border-radius: 45%;
    animation: animate 8s linear infinite;
}

.greenTheme::before {
    background-color: #2ECC40;
}

.greenTheme::after {
    background-color: #A4E6AD;
}

@keyframes animate {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}



.liquidRedTheme::before {
    background-color: #f1903b;
    border-radius: 45%;
    animation: animate 5s linear infinite;
}

.liquidRedTheme::after {
    background-color: #f4a69f;
    border-radius: 48%;
    animation: animate 4s linear infinite;
}


.liquidYellowTheme::before {
    background-color: #FFD700;
    border-radius: 45%;
    animation: animate 6s linear infinite;
}

.liquidYellowTheme::after {
    background-color: #FFECB3;
    border-radius: 48%;
    animation: animate 4s linear infinite;
}


.liquidGreenTheme::before {
    background-color: #04b53f;
    border-radius: 45%;
    animation: animate 5s linear infinite;
}

.liquidGreenTheme::after {
    background-color: #A4E6AD;
    border-radius: 48%;
    animation: animate 4s linear infinite;
}