.report-table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 20px; /* Add border-radius for rounded corners */
    overflow: hidden; /* Hide overflow to prevent content from overflowing rounded corners */
  }
  
  .report-table th,
  .report-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .report-table th {
    /* background-color: #f2f2f2; */
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .report-table td:first-child,
  .report-table th:first-child {
    position: sticky;
    left: 0;
    /* background-color: #f2f2f2; */
  }


  .report-table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #EEF1F6;
  color: #131926;
  border:'1px solid #D3D8E1'
}

td {
  background-color: #ffffff;
  font-size: 14px;
}

.fixed-header {
  position: sticky;
  left: 0;
  z-index: 1;
}



/* Additional styles for dark mode */
.dark {
  /* background-color: #009cbb; */
  /* color: #ffffff; */
  /* background-image: url("../../assets//rateXlightBg.png"); */
}
