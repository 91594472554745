.checkbox-apple {
  position: relative;
  width: 32px;
  height: 16px;
  /* margin: 20px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-apple label {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 16px;
  border-radius: 50px;
  background: linear-gradient(to bottom, #c1ceff, #c1ceff);
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-apple label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.checkbox-apple input[type="checkbox"]:checked+label {
  background: linear-gradient(to bottom, #081439, #081439);
}

.checkbox-apple input[type="checkbox"]:checked+label:after {
  transform: translateX(16px);
}

.checkbox-apple label:hover {
  background: linear-gradient(to bottom, #c1ceff, #c1ceff);
}

.checkbox-apple label:hover:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 16px;
}